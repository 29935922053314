import React, { useCallback, useState } from 'react';

import quicksightUrlApi from '../api/quicksightUrlApi';
import { FetchStatus } from '../constants/fetchStatus';
import { useAuthContext } from './AuthContextProvider';
import { getLogger } from '../utils/logger';
import { DimensionValue } from '../interfaces/logger';
import UnauthorizedAdminAccessError from '../errors/UnauthorizedAdminAccessError';
import { AdminReportData } from '../hooks/useAdminReport';
import { ApolloError } from '@apollo/client';

interface AdminReportUrlState {
	url?: string;
	status: FetchStatus;
	error?: Error;
}
export interface AdminReportUrlContextProps extends AdminReportUrlState {
	handleAdminReportData: (
		data: AdminReportData | undefined,
		loading: boolean,
		error?: ApolloError,
	) => void;
	getUrl: () => void;
	resetStates: () => void;
}

const DEFAULT_ADMIN_REPORT_URL_STATE: AdminReportUrlState = {
	status: FetchStatus.Unspecified,
};

export const AdminReportUrlContext =
	React.createContext<AdminReportUrlContextProps | null>(null);

export const useAdminReportUrlContext = (): AdminReportUrlContextProps => {
	const contextState = React.useContext(AdminReportUrlContext);
	if (contextState === null) {
		throw new Error(
			'useAdminReportUrlContext must be used within a AdminReportUrlProvider tag',
		);
	}
	return contextState;
};

const isExpected = (error: Error): boolean => {
	return [UnauthorizedAdminAccessError].some((e) => error instanceof e);
};

export const AdminReportUrlContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
	const logger = getLogger();
	const { authState } = useAuthContext();
	const [adminReportUrlState, setAdminReportUrlState] =
		useState<AdminReportUrlState>(DEFAULT_ADMIN_REPORT_URL_STATE);

	const handleAdminReportError = (error: ApolloError) => {
		const errorMessage = error.message;
		// Error message when user is not authorized to invoke the resolver: https://tiny.amazon.com/1h6ole4qs
		const errorMessagePattern =
			/Unauthorized to invoke resolver for typeName:(.*) and fieldName:(.*) and for roles:(.*)./;
		const match = errorMessage.match(errorMessagePattern);
		if (match) {
			setAdminReportUrlState({
				status: FetchStatus.ExpectedError,
				error: new UnauthorizedAdminAccessError(errorMessage),
			});
		} else {
			setAdminReportUrlState({
				status: FetchStatus.UnexpectedError,
				error: error,
			});
		}
	};

	const resetStates = useCallback(() => {
		setAdminReportUrlState(DEFAULT_ADMIN_REPORT_URL_STATE);
	}, [setAdminReportUrlState]);

	const handleAdminReportData = useCallback(
		(data: AdminReportData | undefined, loading: boolean, error?) => {
			resetStates();
			if (loading) {
				setAdminReportUrlState({ status: FetchStatus.Loading });
			} else if (error) {
				handleAdminReportError(error);
			} else if (data) {
				setAdminReportUrlState({
					status: FetchStatus.Loaded,
					url: data.adminReport.reportEmbeddingUrl,
				});
			}
		},
		[resetStates],
	);

	const getUrl = useCallback(async () => {
		if (authState.isAuthenticated && logger) {
			resetStates();

			setAdminReportUrlState({ status: FetchStatus.Loading });
			quicksightUrlApi
				.getUrl()
				.then(({ result }) => {
					setAdminReportUrlState({
						status: FetchStatus.Loaded,
						url: result.EmbedUrl,
					});
				})
				.catch((error) => {
					if (isExpected(error)) {
						setAdminReportUrlState({
							status: FetchStatus.ExpectedError,
							error: error,
						});
					} else {
						logger.error(
							'Unexpected error captured in AdminReportUrlContextProvider',
							error,
						);
						logger.counterMetric({
							metricName: 'AdminReportUrlContextError',
							dimensionValue: DimensionValue.Error,
						});
						setAdminReportUrlState({
							status: FetchStatus.UnexpectedError,
							error: error,
						});
					}
				});
		}
	}, [resetStates, authState.isAuthenticated, logger]);

	return (
		<AdminReportUrlContext.Provider
			value={{
				...adminReportUrlState,
				handleAdminReportData: handleAdminReportData,
				getUrl: getUrl,
				resetStates,
			}}
		>
			{children}
		</AdminReportUrlContext.Provider>
	);
};

export default AdminReportUrlContextProvider;
